body {
  margin: 0;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: xx-large;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.content-container {
  min-height: calc(100vh - 140px);
}

.footer--pin {
  position: relative;
  background-color: cornflowerblue;
  left: 0;
  bottom: 0;
}
